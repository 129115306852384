import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';

import appReducer from './features/app/appSlice';
import authReducer from './features/auth/authSlice';
import webhookReducer from './features/webhooks/webhookSlice';
import accountReducer from './features/accounts/accountSlice';
import partnerReducer from './features/partners/partnerSlice';
import customerReducer from './features/customers/customerSlice';
import constantReducer from './features/constants/constantSlice';
import apiConfigReducer from './features/api-configs/apiConfigSlice';
import finmoPayinReducer from './features/finmo-payins/finmoPayinSlice';
import transactionReducer from './features/transactions/transactionSlice';
import finmoPayoutReducer from './features/finmo-payouts/finmoPayoutSlice';
import beneficiaryReducer from './features/beneficiaries/beneficiarySlice';
import customerKycsReducer from './features/customer-kycs/customerKycSlice';
import customerKycDocumentReducer from './features/customer-kyc-documents/customerKycDocumentSlice';
import transactionDocumentReducer from './features/transactions/transactionDocumentSlice';
import customerKycLogsReducer from './features/customer-kyc-logs/customerKycLogsSlice';
import zaiTransactionReducer from './features/transactions/zaiTransactionSlice';
import transactionLogReducer from './features/transactions/transactionLogSlice';
import webhookHeaderReducer from './features/webhook-headers/webhookHeaderSlice';
import fundingSourceReducer from './features/funding-sources/fundingSourceSlice';
import ausTrackReportReducer from './features/ausTrackReports/ausTrackReportsSlice';
import partnerCountryReducer from './features/partner-countries/partnerCountrySlice';
import partnerCredentialReducer from './features/partner-credentials/partnerCredentialSlice';
import partnerFundingSourceReducer from './features/partner-funding-sources/partnerFundingSourceSlice';
import licensingPartnerReducer from './features/licensing-partners/licensingPartnerSlice';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware({
  onError: (err) => {
    //  READ DOCS: https://redux-saga.js.org/docs/basics/ErrorHandling/#onerror-hook
    //  Errors in forked tasks bubble up to their parents until it is caught or reaches the root saga.
    //  If an error propagates to the root saga the whole saga tree is already terminated.
    //  The preferred approach, in this case, to use onError hook to report an exception,
    //  inform a user about the problem and gracefully terminate your app.
  },
});

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    accounts: accountReducer,
    partners: partnerReducer,
    licensingPartners: licensingPartnerReducer,
    webhooks: webhookReducer,
    constants: constantReducer,
    customers: customerReducer,
    apiConfigs: apiConfigReducer,
    finmoPayins: finmoPayinReducer,
    finmoPayouts: finmoPayoutReducer,
    transactions: transactionReducer,
    beneficiaries: beneficiaryReducer,
    customerKycs: customerKycsReducer,
    customerKycDocument: customerKycDocumentReducer,
    customerKycLogs: customerKycLogsReducer,
    webhookHeaders: webhookHeaderReducer,
    fundingSources: fundingSourceReducer,
    partnerCountries: partnerCountryReducer,
    partnerCredentials: partnerCredentialReducer,
    partnerFundingSources: partnerFundingSourceReducer,
    ausTrackReports: ausTrackReportReducer,
    zaiTransactions: zaiTransactionReducer,
    transactionLogs: transactionLogReducer,
    transactionDocument: transactionDocumentReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ thunk: false, serializableCheck: false }).prepend(sagaMiddleware);
  },
  // eslint-disable-next-line no-undef
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export default store;
