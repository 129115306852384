import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GroupedTableView from 'core/table/GroupedTableView';

import { getLicensingPartner } from 'app/features/licensing-partners/licensingPartnerSaga';
import styled from '@emotion/styled';

const isValidUrl = (string) => {
  try {
    let url = new URL(string);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (error) {
    return false;
  }
};

const TextFlowWrapper = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '62rem',
  fontWeight: '400',
  fontSize: '14px',
}));

export default function LicensingPartner({ licensingPartnerId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLicensingPartner(licensingPartnerId));
  }, [licensingPartnerId]);

  const viewLicensingPartnerData = useMemo(() => [
    {
      groupName: '',
      groupData: [
        {
          label: 'Licensing Partner ID',
          data: 'id',
        },
        {
          label: 'Name',
          data: 'name',
        },
        {
          label: 'Licensing Number',
          data: 'license_number',
          get: ({ license_number }) => <TextFlowWrapper>{license_number}</TextFlowWrapper>,
        },
        {
          label: 'Logo',
          data: 'logo',
          get: ({ logo }) =>
            isValidUrl(logo) ? (
              <img
                src={logo}
                alt="partner-logo"
                style={{
                  objectFit: 'cover',
                  width: '10%',
                  height: '10%',
                }}
              />
            ) : (
              <TextFlowWrapper>{logo}</TextFlowWrapper>
            ),
        },
        {
          label: 'Privacy Policy',
          data: 'privacy_policy_link',
          get: ({ privacy_policy_link }) => (
            <TextFlowWrapper>{privacy_policy_link}</TextFlowWrapper>
          ),
        },
        {
          label: 'Terms and Conditions',
          data: 'terms_and_conditions_link',
          get: ({ terms_and_conditions_link }) => (
            <TextFlowWrapper>{terms_and_conditions_link}</TextFlowWrapper>
          ),
        },

        {
          label: 'Created At',
          data: 'created_ts',
        },
        {
          label: 'Updated At',
          data: 'updated_ts',
        },
      ],
    },
  ]);

  const { isLoading, data: LicensingPartners } = useSelector(
    (state) => state.licensingPartners.show,
  );

  return (
    <Grid container spacing={2}>
      {viewLicensingPartnerData?.map((group, i) => (
        <GroupedTableView
          key={i}
          title={group.groupName}
          data={group.groupData}
          item={LicensingPartners}
          isLoading={isLoading}
        />
      ))}
    </Grid>
  );
}

LicensingPartner.propTypes = {
  licensingPartnerId: PropTypes.string,
};
