import Error from 'core/errors/Error';
import React, { useEffect } from 'react';
import Button from 'core/buttons/Button';
import { compose } from '@reduxjs/toolkit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import withRole from 'hoc/withRole';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import LicensingPartners from 'components/LicensingPartner/LicensingPartners';
import { resetGetLicensingPartnerFailure } from 'app/features/licensing-partners/licensingPartnerSlice';
import LicensingPartnerBriefDetailsModal from 'components/LicensingPartner/LicensingPartnerBriefDetailsModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';

const ListLicensingPartner = ({ isNetworkError }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const licensingPartnerState = useSelector((state) => state.licensingPartners.index);

  const { isError, errorCode } = licensingPartnerState;

  useEffect(() => {
    if (isError) dispatch(resetGetLicensingPartnerFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetLicensingPartnerFailure())}
      />
    );

  return (
    <PageContent
      title="Licensing Partner"
      rightTopEndContent={
        <Button
          onClick={() => navigate(buildRoute('licensing-partners.create'))}
          color="primary"
          startIcon={<AddIcon />}
        >
          ADD LICENSING PARTNER
        </Button>
      }
    >
      <LicensingPartners />
      <LicensingPartnerBriefDetailsModal />
    </PageContent>
  );
};

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ListLicensingPartner);
