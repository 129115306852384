import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useEffect, useRef } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Button from 'core/buttons/Button';
import TextButton from 'core/buttons/TextButton';
import useQueryParams from 'hooks/useQueryParams';
import FormContainer from 'core/forms/FormContainer';
import FormTextField from 'hook-form-controls/FormTextField';

import {
  createLicensingPartner,
  updateLicensingPartner,
} from 'app/features/licensing-partners/licensingPartnerSaga';

const licensingPartnerSchema = Yup.object().shape({
  name: Yup.string().required('Licensing Partner Name is Required'),
  logo: Yup.string().url().required('Licensing Partner Logo is Required'),
  license_number: Yup.string().required('Licensing Partner License Number is Required'),
  privacy_policy_link: Yup.string().url().required('Licensing Partner Policy Link is Required'),
  website_link: Yup.string().url().required('Licensing Partner Website Link is Required'),
  terms_and_conditions_link: Yup.string()
    .url()
    .required('Licensing Partner Terms and Conditions Link is Required'),
});

function LicensingPartnerForm({ isAddMode, licensingPartner, retries, isCreated }) {
  const submitButtonRef = useRef(null);
  const [query] = useQueryParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      ...licensingPartner,
      ...(isAddMode && query.licensingPartnerId
        ? {
            licensing_partner_id: query.licensingPartnerId,
          }
        : {}),
    },
    resolver: yupResolver(licensingPartnerSchema),
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = methods;

  const onSubmit = (data) => {
    dispatch(
      isAddMode
        ? createLicensingPartner(data)
        : updateLicensingPartner({
            licensingPartnerId: licensingPartner?.id,
            ...data,
          }),
    );
  };

  useEffect(() => {
    if (retries > 0) submitButtonRef.current.click();
  }, [retries]);

  return (
    <FormContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={3} columnSpacing={4}>
            <Grid item sm={12} md={6}>
              <FormTextField name="name" label="Licensing Partner name" />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormTextField name="logo" label="Logo" />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormTextField name="license_number" label="License Number" />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormTextField name="website_link" label="Website Link" />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormTextField name="privacy_policy_link" label="Privacy Policy Link" />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormTextField name="terms_and_conditions_link" label="Terms and conditions Link" />
            </Grid>

            <Grid item sm={12} md={12}>
              <Box display="flex" justifyContent="space-between">
                <TextButton type="button" color="error" onClick={() => navigate(-1)}>
                  Cancel
                </TextButton>
                <Button
                  ref={submitButtonRef}
                  type="submit"
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                >
                  {isAddMode ? 'Create New Licensing Partner' : 'Update Licensing Partner'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </FormContainer>
  );
}

export default compose(connect(mapStateToProps))(LicensingPartnerForm);

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    retries: ownProps.isAddMode
      ? state.licensingPartners.create.retries
      : state.licensingPartners.update.retries,
    isCreated: state.licensingPartners.create.isCreated,
  };
}

LicensingPartnerForm.propTypes = {
  isAddMode: PropTypes.bool.isRequired,
  licensingPartner: PropTypes.object,
  retries: PropTypes.number,
  isCreated: PropTypes.bool,
};
