import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import constants from 'app/config/constants';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import Error from 'core/errors/Error';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import TableContainer from 'core/table/TableContainer';
import TanstackReactTable from 'core/table/TanstackReactTable';

import { buildRoute } from 'routes';
import DateUtils from 'utils/DateUtils';
import Dimensions from 'app/config/Dimensions';
import LicensingPartnerActions from './LicensingPartnerActions';
import LicensingPartnerPagination from './LicensingPartnerPagination';
import { getLicensingPartners } from 'app/features/licensing-partners/licensingPartnerSaga';
import { getLicensingPartnerBriefDetailsInit } from 'app/features/licensing-partners/licensingPartnerSlice';

const isValidUrl = (string) => {
  try {
    let url = new URL(string);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (error) {
    return false;
  }
};

const TextFlowWrapper = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '20rem',
}));

function LicensingPartners() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const licensingPartnersState = useSelector((state) => state.licensingPartners.index);

  const { data, params, isLoading, isError, errorCode } = licensingPartnersState;

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        id: 'name',
        header: 'Licensing Partner Name',
        accessorFn: (row) => row.name,
      },
      {
        id: 'licensing-number',
        header: 'License Number',
        accessorFn: (row) => row.license_number,
      },
      {
        id: 'website_link',
        header: 'Website',
        accessorFn: (row) => row.website_link,
        cell: ({ row }) => <TextFlowWrapper>{row.original.website_link}</TextFlowWrapper>,
      },

      {
        id: 'is_deleted',
        header: 'Is Deleted ?',
        accessorFn: (row) => row.is_deleted,
        cell: (info) => {
          return <VerifiedBadge isVerified={!!info.getValue()} />;
        },
        isVisible: false,
      },

      {
        id: 'logo',
        header: 'Logo',
        accessorFn: (row) => row.logo,
        cell: ({ row }) =>
          isValidUrl(row.original.logo) ? (
            <img src={row.original.logo} height={80} width={80} />
          ) : (
            <website_link>{row.original.logo}</website_link>
          ),
      },
      {
        id: 'privacy_policy_link',
        header: 'Privacy Policy',
        accessorFn: (row) => row.privacy_policy_link,
        cell: ({ row }) => <TextFlowWrapper>{row.original.privacy_policy_link}</TextFlowWrapper>,
      },
      {
        id: 'terms_and_conditions_link',
        header: 'Licensing Number',
        accessorFn: (row) => row.terms_and_conditions_link,
        cell: ({ row }) => (
          <TextFlowWrapper>{row.original.terms_and_conditions_link}</TextFlowWrapper>
        ),
      },

      {
        id: 'created_ts',
        header: 'Created At',
        accessorFn: (row) => (row.created_ts ? DateUtils.isoToString(row.created_ts) : '-'),
      },
      {
        id: 'updated_ts',
        header: 'Updated At',
        accessorFn: (row) => (row.updated_ts ? DateUtils.isoToString(row.updated_ts) : '-'),
      },
      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,
        cell: ({ row }) => (
          <Box display="flex" gap={1}>
            <IconButton
              size={Dimensions.table.iconButton.size}
              onClick={() => {
                dispatch(
                  getLicensingPartnerBriefDetailsInit({
                    showBriefDetailModal: true,
                    licensingPartnerId: row.original.id,
                  }),
                );
              }}
            >
              <AspectRatioIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
            <IconButton
              size={Dimensions.table.iconButton.size}
              onClick={() => navigate(buildRoute('licensing-partners.show', row.original.id))}
            >
              <FullscreenIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
          </Box>
        ),
      },
    ],
    [JSON.stringify(data)],
  );

  const [columnVisibility, setColumnVisibility] = useState(
    columns
      .filter((c) => c?.isVisible === false)
      .map((c) => c.id)
      .reduce((a, k) => ({ ...a, [k]: false }), {}),
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(getLicensingPartners({ ...params }));
  }, [JSON.stringify(params)]);

  if (isError) return <Error status={errorCode} />;

  return (
    <TableContainer>
      <LicensingPartnerActions table={table} />
      <TanstackReactTable
        table={table}
        isLoading={isLoading}
        data={data}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />

      <LicensingPartnerPagination />
    </TableContainer>
  );
}

export default LicensingPartners;
